export const DrinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.25 13C18.25 13 20.25 8.523 20.25 3H4.25C4.25 8.523 6.25 13 12.25 13ZM12.25 13V20M12.25 20L16.25 22H8.25L12.25 20ZM5.25 8H19.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
