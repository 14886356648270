import { ActionType, ChannelItem, ContentType } from '@/types/preshow'
import { isVideo, transitionOut } from '@/utils'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ActionBar } from '@/components/upseat-ui/contentChannel/ActionBar'
import { Arrow } from '@/assets/Arrow'
import { GridTile } from '@/components/upseat-ui/contentChannel/GridTile'
import { MainTile } from '@/components/upseat-ui/contentChannel/MainTile'
import { MoveUp } from 'lucide-react'
import NavLayout from '@/layouts/NavLayout'
import { getPreShow } from '@/service/PreShowEngagement/getPreShow'
import { motion } from 'framer-motion'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useContentChannelStore } from '@/context/useContentChannelStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useUserStore } from '@/context/useUserStore'

export const ContentChannel = () => {
  const { id } = useParams()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const { user } = useUserStore()
  const { booking } = useExistingBookingStore()
  const navigate = useNavigate()
  const screen = useScreenSize()
  const { setCasting, setBadgeUrl } = useMeetTheCastStore()
  const { setFullScreenChannel } = useFullScreenContentStore()
  const { channelContent, setChannelContent } = useContentChannelStore()
  const [isExclusive, setIsExclusive] = useState(true)
  const [mainContentLoaded, setMainContentLoaded] = useState(false)
  const [channelContentLoaded, setChannelContentLoaded] = useState(false)
  const [gridContent, setGridContent] = useState<ChannelItem[]>([])
  const [gridContentLoaded, setGridContentLoaded] = useState(false)

  useEffect(() => {
    if (!id && !booking) {
      navigate('/tickets')
    } else {
      const eventIUD = id || booking?.preshow.event_uid
      getPreShow(eventIUD as string)
        .then(async (res) => {
          setChannelContent(res.data)
          setCasting(
            res.data.actions.filter(
              (action: ActionType) => action.action_type === ContentType.ACTOR,
            )[0],
          )
          setBadgeUrl(res.data.badge_url)

          const fullScreenContent = res.data.channel.filter(
            (cont: ChannelItem) => isVideo(cont.content_url),
          ) as ChannelItem[]

          setFullScreenChannel(
            fullScreenContent.sort((a, b) => a.y_axis - b.y_axis),
          )
        })
        .catch((e: Error) => {
          console.log('error', e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!gridContentLoaded) {
      setGridContent(
        (channelContent.channel as ChannelItem[])
          .filter((cont: ChannelItem) => cont.y_axis > 0)
          .sort((a: ChannelItem, b: ChannelItem) => a.y_axis - b.y_axis),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelContent])

  useEffect(() => {
    if (gridContent.length) {
      setGridContentLoaded(true)
    }
  }, [gridContent])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: { opacity: 0, transition: transitionOut },
  }

  const childVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  }

  const scrollToTop = () => {
    if (scrollContainerRef.current && screen.width > 1024) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      // Fallback to window scroll for mobile
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    // Force layout update on mobile
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }

  return (
    <NavLayout ref={scrollContainerRef}>
      <motion.section
        id="pre-show-content-container"
        className={`flex flex-col justify-start items-center h-full w-full min-h-[100svh] bg-purple`}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
      >
        {useConditionalRender(
          !!channelContent && !!user,
          <>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.3 }}
              onClick={() => navigate(`/tickets`, { replace: true })}
              className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              <Arrow />
            </motion.span>
          </>,
        )}

        {useConditionalRender(
          !!channelContent.channel?.length,
          <main
            className={`w-full flex flex-col items-center ${
              mainContentLoaded ? 'overflow-y-auto' : 'overflow-hidden hidden'
            }
          }`}
          >
            <MainTile
              childVariants={childVariants}
              setMainContentLoaded={setMainContentLoaded}
              badge_url={channelContent.badge_url}
              video_url={
                (channelContent.channel as ChannelItem[]).find(
                  (cont: ChannelItem) => cont.y_axis === 0,
                )?.content_url as string
              }
              video_description={
                (channelContent.channel as ChannelItem[]).find(
                  (cont: ChannelItem) => cont.y_axis === 0,
                )?.description as string
              }
              name={channelContent.name}
              isExclusive={isExclusive}
            />

            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: gridContentLoaded ? 1 : 0,
                height: gridContentLoaded ? 'auto' : 0,
              }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <GridTile
                channelContent={gridContent}
                childVariants={childVariants}
                badge_url={channelContent.badge_url}
                video_url={channelContent.poster_url}
                video_description={channelContent.description}
                name={channelContent.name}
                channelContentLoaded={channelContentLoaded}
                setChannelContentLoaded={setChannelContentLoaded}
              />
            </motion.div>

            {channelContentLoaded && (
              <ActionBar actions={channelContent.actions} />
            )}

            <footer className="w-full bg-purple flex flex-col z-50 py-12 gap-1 justify-center items-center">
              <p
                className="capitalize text-yellow text-sm flex flex-col gap-1 items-center cursor-pointer"
                onClick={() => {
                  scrollToTop()
                }}
              >
                <MoveUp color={`rgba(244, 255, 104, 1)`} />
                {`back to top`}
              </p>
            </footer>
          </main>,
        )}
      </motion.section>
    </NavLayout>
  )
}
