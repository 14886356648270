import { Booking, Order } from '../types/booking'

import { create } from 'zustand'

// Define a type for the user object

// Define the store's state
type ExistingBookingState = {
  booking: Booking | undefined
  order: Order | undefined
  loadingBooking: boolean
  setBooking: (booking: Booking | undefined) => void
  setOrder: (order: Order | undefined) => void
  removeBooking: () => void
  setLoadingBooking: (loadingBooking: boolean) => void
}

// Create the store
const useExistingBookingStore = create<ExistingBookingState>((set) => ({
  booking: undefined,
  order: undefined,
  loadingBooking: false,
  setBooking: (booking: Booking | undefined) => set({ booking }),
  setOrder: (order: Order | undefined) => set({ order }),
  removeBooking: () => set({ booking: undefined }),
  setLoadingBooking: (loadingBooking) => set({ loadingBooking }),
}))

export default useExistingBookingStore
