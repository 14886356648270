export const ArrowLong = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.6552 10.8895L15.8846 8.76551C15.9585 8.69509 16 8.59958 16 8.5C16 8.40042 15.9585 8.30491 15.8846 8.2345L13.6552 6.11045C13.6001 6.05778 13.5298 6.02187 13.4533 6.00729C13.3767 5.99271 13.2974 6.00011 13.2253 6.02854C13.1531 6.05698 13.0915 6.10518 13.0482 6.16703C13.0049 6.22888 12.9818 6.3016 12.9819 6.37595L12.9824 8.12458L0 8.12404V8.87596L12.9824 8.87543L12.9819 10.624C12.9818 10.6984 13.0049 10.7711 13.0482 10.833C13.0915 10.8948 13.1531 10.943 13.2253 10.9715C13.2974 10.9999 13.3767 11.0073 13.4533 10.9927C13.5298 10.9781 13.6001 10.9422 13.6552 10.8895Z"
      fill="#19162C"
    />
  </svg>
)
