import { CastChannelAction, ContentType } from '@/types/preshow'

import { create } from 'zustand'

// Define the store's state
type MeetTheCastState = {
  badge_url: string
  duration: number
  casting: CastChannelAction
  setBadgeUrl: (value: string) => void
  setCasting: (value: CastChannelAction) => void
}

// Create the store
export const useMeetTheCastStore = create<MeetTheCastState>((set) => ({
  badge_url: '',
  duration: 3500,
  casting: {
    action_type: '' as ContentType,
    title: '',
    enabled: false,
    content: [
      {
        name: '',
        x_axis: 0,
        character: '',
        description: '',
        content_url: '',
        content_length: 0,
      },
    ],
  },
  setBadgeUrl: (value) => set({ badge_url: value }),
  setCasting: (value) => set({ casting: value }),
}))
