import { Basket } from '../types'
import { Booking } from '@/types/booking'
import { ReactNode } from 'react'
import { create } from 'zustand'

// Define the store's state type
type WalletState = {
  wallet: Booking[] | undefined
  basket: Basket | undefined
  ticketVideoOnMap: Record<string, boolean> // Map to handle video states per ticket
  setWallet: (wallet: Booking[] | undefined) => void
  removeUpBooking: () => void
  setBasket: (basket: Basket) => void
  removeBasket: () => void
  setTicketVideoOn: (ticketId: string, value: boolean) => void // Per-ticket setter
}

// Create the store
export const useTicketsStore = create<WalletState>((set) => ({
  wallet: undefined,
  basket: undefined,
  ticketVideoOnMap: {}, // Initialize as an empty object
  setWallet: (wallet) => set({ wallet }),
  removeUpBooking: () => set({ wallet: undefined }),
  setBasket: (basket) => set({ basket }),
  removeBasket: () => set({ basket: undefined }),
  setTicketVideoOn: (ticketId, value) =>
    set((state) => ({
      ticketVideoOnMap: { ...state.ticketVideoOnMap, [ticketId]: value },
    })), // Update the state for the specific ticket
}))
