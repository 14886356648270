import { Plus, Star } from 'lucide-react'
import { filterResults, isCurrentSeat, selectSeat } from '../..'

import { Order } from '@/types/booking'
import { Seat } from '@/types'
import useEventStore from '@/context/useEventStore'

interface CircleCentralProps {
  order: Order | undefined
  discountRange: number[]
  selectedSeats: Seat[]
  setSelectedSeats: (selectedSeats: Seat[] | undefined) => void
  setSeatAsideWarning: (value: React.SetStateAction<boolean>) => void
  mapSeatsByRow: (
    mapSeats: Seat[],
    location?: string,
  ) => { row: string; seats: Seat[] }[]
}

export const CircleCentral = ({
  order,
  discountRange,
  selectedSeats,
  setSelectedSeats,
  setSeatAsideWarning,
  mapSeatsByRow,
}: CircleCentralProps) => {
  const { mapSeats } = useEventStore()

  return (
    <div className="seat-map absolute bottom-[0px]">
      <p className="text-grey w-full text-center uppercase text-[12px]">{`Circle`}</p>
      {mapSeats &&
        mapSeatsByRow(mapSeats, 'Circle').map((row, index) => {
          return (
            <div
              className={`flex items-center w-full my-2 ${
                index === 0 && 'mt-1'
              }`}
              key={row.row}
            >
              {row.row !== 'F' ? (
                <div className="w-full relative flex justify-center gap-[3px]">
                  {row.seats.reverse().map((seat, index) => {
                    return (
                      <div
                        key={seat.seat_id}
                        onClick={() =>
                          selectSeat(
                            seat,
                            selectedSeats,
                            setSelectedSeats,
                            setSeatAsideWarning,
                          )
                        }
                        style={{
                          width: `12px`,
                          height: `12px`,
                        }}
                        className={`seat flex items-center justify-center ${row.row + seat.number}
              ${selectedSeats.includes(seat) && 'selected-seat'}
              ${
                isCurrentSeat(
                  order,
                  seat.row + seat.number.toString(),
                  seat.location,
                ) && 'current-seat pointer-events-none'
              }
              ${
                !selectedSeats.includes(seat) &&
                !isCurrentSeat(
                  order,
                  seat.row + seat.number.toString(),
                  seat.location,
                ) &&
                filterResults(seat.tier, discountRange)
              }
              ${
                !selectedSeats.includes(seat) &&
                !isCurrentSeat(
                  order,
                  seat.row + seat.number.toString(),
                  seat.location,
                ) &&
                order?.tickets.length === selectedSeats.length &&
                'opacity-[.2] pointer-events-none'
              }
              `}
                      >
                        <span
                          style={{
                            backgroundColor: seat.tier
                              ? 'var(--tier' + seat.tier + ')'
                              : 'grey',
                          }}
                          className={`relative flex items-center justify-center h-[12px] w-[12px] block rounded-[4px]
          ${selectedSeats.includes(seat) && `!bg-blue`}
                ${
                  isCurrentSeat(
                    order,
                    seat.row + seat.number.toString(),
                    seat.location,
                  ) && `!bg-[white] border-[2px] border-[black]`
                }`}
                        >
                          {isCurrentSeat(
                            order,
                            seat.row + seat.number.toString(),
                            seat.location,
                          ) && (
                            <Plus
                              color="black"
                              style={{
                                rotate: '45deg',
                                position: 'absolute',
                              }}
                              width={18}
                              height={20}
                            />
                          )}
                          {selectedSeats.includes(seat) && (
                            <Star color="white" fill="white" size={8} />
                          )}
                        </span>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="flex justify-center w-full gap-20">
                  <div className="relative flex justify-center gap-[3px]">
                    {row.seats
                      .filter(
                        (seat) =>
                          parseInt(seat.number) < 15 &&
                          parseInt(seat.number) > 10,
                      )
                      .reverse()
                      .map((seat, index) => {
                        return (
                          <div
                            key={seat.seat_id}
                            onClick={() =>
                              selectSeat(
                                seat,
                                selectedSeats,
                                setSelectedSeats,
                                setSeatAsideWarning,
                              )
                            }
                            style={{
                              width: `12px`,
                              height: `12px`,
                            }}
                            className={`seat flex items-center justify-center ${row.row + seat.number}
            ${selectedSeats.includes(seat) && 'selected-seat'}
            ${
              isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) && 'current-seat pointer-events-none'
            }
            ${
              !selectedSeats.includes(seat) &&
              !isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) &&
              filterResults(seat.tier, discountRange)
            }
            ${
              !selectedSeats.includes(seat) &&
              !isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) &&
              order?.tickets.length === selectedSeats.length &&
              'opacity-[.2] pointer-events-none'
            }
            `}
                          >
                            <span
                              style={{
                                backgroundColor: seat.tier
                                  ? 'var(--tier' + seat.tier + ')'
                                  : 'grey',
                              }}
                              className={`relative flex items-center justify-center h-[12px] w-[12px] block rounded-[4px]
        ${selectedSeats.includes(seat) && `!bg-blue`}
              ${
                isCurrentSeat(
                  order,
                  seat.row + seat.number.toString(),
                  seat.location,
                ) && `!bg-[white] border-[2px] border-[black]`
              }`}
                            >
                              {isCurrentSeat(
                                order,
                                seat.row + seat.number.toString(),
                                seat.location,
                              ) && (
                                <Plus
                                  color="black"
                                  style={{
                                    rotate: '45deg',
                                    position: 'absolute',
                                  }}
                                  width={18}
                                  height={20}
                                />
                              )}
                              {selectedSeats.includes(seat) && (
                                <Star color="white" fill="white" size={8} />
                              )}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                  <div className="relative flex justify-center gap-[3px]">
                    {row.seats
                      .filter((seat) => parseInt(seat.number) < 5)
                      .reverse()
                      .map((seat, index) => {
                        return (
                          <div
                            key={seat.seat_id}
                            onClick={() =>
                              selectSeat(
                                seat,
                                selectedSeats,
                                setSelectedSeats,
                                setSeatAsideWarning,
                              )
                            }
                            style={{
                              width: `12px`,
                              height: `12px`,
                            }}
                            className={`seat flex items-center justify-center ${row.row + seat.number}
            ${selectedSeats.includes(seat) && 'selected-seat'}
            ${
              isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) && 'current-seat pointer-events-none'
            }
            ${
              !selectedSeats.includes(seat) &&
              !isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) &&
              filterResults(seat.tier, discountRange)
            }
            ${
              !selectedSeats.includes(seat) &&
              !isCurrentSeat(
                order,
                seat.row + seat.number.toString(),
                seat.location,
              ) &&
              order?.tickets.length === selectedSeats.length &&
              'opacity-[.2] pointer-events-none'
            }
            `}
                          >
                            <span
                              style={{
                                backgroundColor: seat.tier
                                  ? 'var(--tier' + seat.tier + ')'
                                  : 'grey',
                              }}
                              className={`relative flex items-center justify-center h-[12px] w-[12px] block rounded-[4px]
        ${selectedSeats.includes(seat) && `!bg-blue`}
              ${
                isCurrentSeat(
                  order,
                  seat.row + seat.number.toString(),
                  seat.location,
                ) && `!bg-[white] border-[2px] border-[black]`
              }`}
                            >
                              {isCurrentSeat(
                                order,
                                seat.row + seat.number.toString(),
                                seat.location,
                              ) && (
                                <Plus
                                  color="black"
                                  style={{
                                    rotate: '45deg',
                                    position: 'absolute',
                                  }}
                                  width={18}
                                  height={20}
                                />
                              )}
                              {selectedSeats.includes(seat) && (
                                <Star color="white" fill="white" size={8} />
                              )}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}
            </div>
          )
        })}

      <p className="text-center text-[11px] relative bottom-2">{`First Floor`}</p>
    </div>
  )
}
