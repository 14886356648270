import React, { ReactNode } from 'react'

import useScreenSize from '@/hooks/useScreenSize'

type DefaultLayoutProps = {
  children: ReactNode
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const screenSize = useScreenSize()

  return (
    <div
      className={`app-view w-full overflow-x-hidden bg-purple ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw]'}`}
    >
      {children}
    </div>
  )
}

export default DefaultLayout
