export enum ContentType {
  STATIC = 'STATIC',
  ACTOR = 'ACTOR',
  EXTERNAL = 'EXTERNAL',
  SHARE = 'SHARE',
}

export type ChannelAction = ActionType[]

export type ActionType =
  | CastChannelAction
  | BarChannelAction
  | MerchChannelAction
  | ShareChannelAction

export interface Channel {
  uid: string
  name: string
  poster_url: string
  badge_url: string
  description: string
  channel: ChannelItem[] | boolean[]
  actions: (
    | CastChannelAction
    | BarChannelAction
    | MerchChannelAction
    | ShareChannelAction
  )[]
}

export interface ChannelItem {
  content_url: string
  description: string | null
  x_axis: number
  y_axis: number
}

export interface CastContentItem {
  name: string
  x_axis: number
  character: string
  description: string
  content_url: string
  content_length: number
}

export interface CastChannelAction {
  action_type: ContentType
  title: string
  enabled: boolean
  content?: CastContentItem[]
}

export interface BarChannelAction {
  action_type: ContentType
  title: string
  enabled: boolean
  content: { external_link?: string }
}

export interface MerchChannelAction {
  action_type: ContentType
  title: string
  enabled: boolean
  content: {
    external_link?: string
  }
}

export interface ShareChannelAction {
  action_type: ContentType
  title: string
  enabled: boolean
  content: {
    body: string
    subject: string
  }
}
