import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils'
import { TicketStates } from '@/types'

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center bg-purple font-bold capitalize w-fit min-w-[10vw] text-[yellow] whitespace-nowrap text-base font-body font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      size: {
        default: 'px-6 py-4 leading-[11px]',
        small: 'px-4 py-2',
        textOnly: 'px-1 py-1',
      },
      upgradeDisabledDueTime: {
        true: 'pointer-events-none bg-purple5 text-purple3',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  upgradeDisabledDueTime?: boolean
  ticketStateCode: number
  ticketStateMsg: string
}

const TicketButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      size,
      upgradeDisabledDueTime,
      asChild = false,
      ticketStateCode,
      ticketStateMsg,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    upgradeDisabledDueTime = ticketStateCode === 2

    return (
      <Comp
        className={cn(
          buttonVariants({ size, upgradeDisabledDueTime, className }),
        )}
        ref={ref}
        id="ticket-btn"
        {...props}
      >
        {ticketStateCode === 3
          ? TicketStates.UPGRADED
          : ticketStateCode === 0
            ? TicketStates.VIEW_TICKET
            : ticketStateMsg}
      </Comp>
    )
  },
)
TicketButton.displayName = 'Button'

export { TicketButton, buttonVariants }
