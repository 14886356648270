type TicketImageProps = {
  src: string
  loaded: boolean
  setLoaded: (loaded: boolean) => void
  handleFlip?: () => void
}

export default function TicketImage({
  src,
  loaded,
  setLoaded,
  handleFlip,
}: TicketImageProps) {
  return (
    <div
      onClick={handleFlip && handleFlip}
      className="relative w-full h-full bg-purple"
    >
      <img
        src={src}
        draggable={false}
        alt="ticket"
        className={`w-full h-full object-cover transition-opacity duration-500
          ${loaded ? 'opacity-100' : 'opacity-0'}
        `}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}
