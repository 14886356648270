export const CastIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.86159 19.45C7.756 19.0426 6.77214 18.3608 6.00246 17.4687C5.23278 16.5765 4.70255 15.5034 4.46159 14.35L2.80159 6.54001C2.56159 5.46001 3.25159 4.40001 4.33159 4.17001L14.1016 2.10001L14.1316 2.09001C15.2016 1.88001 16.2516 2.57001 16.4716 3.63001L16.8216 5.30001L21.1716 6.23001H21.2016C22.2516 6.47001 22.9316 7.53001 22.7116 8.59001L21.0516 16.41C20.86 17.3083 20.4933 18.1601 19.9725 18.9166C19.4516 19.6732 18.7868 20.3198 18.0161 20.8194C17.2453 21.319 16.3837 21.6618 15.4804 21.8284C14.5771 21.9949 13.6498 21.9819 12.7516 21.79C11.2281 21.4711 9.85721 20.6464 8.86159 19.45ZM20.7516 8.18001L10.9816 6.10001L9.32159 13.92V13.95C8.75159 16.63 10.4816 19.27 13.1716 19.84C15.8616 20.41 18.5216 18.69 19.0916 16L20.7516 8.18001ZM16.7516 16.5C16.4359 17.035 15.9599 17.4572 15.3909 17.7067C14.822 17.9561 14.189 18.0203 13.5816 17.89C12.9758 17.761 12.4255 17.4458 12.0078 16.9886C11.59 16.5313 11.3256 15.9549 11.2516 15.34L16.7516 16.5ZM9.22159 5.17001L4.75159 6.13001L6.41159 13.94L6.42159 13.97C6.57159 14.68 6.87159 15.32 7.28159 15.87C7.18159 15.1 7.20159 14.3 7.37159 13.5L7.80159 11.5C7.35159 11.42 6.96159 11.17 6.75159 10.81C6.81159 10.2 7.31159 9.66001 8.00159 9.50001H8.25159L9.03159 5.69001C9.07159 5.50001 9.13159 5.33001 9.22159 5.17001ZM15.7816 12.23C16.1016 11.7 16.7816 11.42 17.4716 11.57C18.1616 11.71 18.6616 12.24 18.7516 12.86C18.4216 13.38 17.7516 13.66 17.0516 13.5C16.3616 13.37 15.8616 12.84 15.7816 12.23ZM10.9016 11.19C11.2216 10.66 11.8916 10.38 12.5816 10.53C13.2516 10.67 13.7816 11.21 13.8616 11.82C13.5316 12.34 12.8616 12.63 12.1716 12.5C11.4816 12.33 10.9816 11.8 10.9016 11.19ZM12.7216 4.43001L14.6816 4.85001L14.5216 4.05001L12.7216 4.43001Z"
        fill="white"
      />
    </svg>
  )
}
