import { fetchAllBookingsFromDB, getUniqueId } from '@/utils'
import { useEffect, useState } from 'react'

import { Booking } from '@/types/booking'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { TicketCardBackFace } from '@/components/upseat-ui/tickets/TicketCardBackFace'
import { UpSeatLogo } from '@/assets/upseat_logo'
import useScreenSize from '@/hooks/useScreenSize'

export const OfflineWallet = () => {
  const [storedBookings, setStoredBookings] = useState<Booking[]>([])
  const screenSize = useScreenSize()

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsFromDB = await fetchAllBookingsFromDB()
        setStoredBookings(bookingsFromDB)
      } catch (error) {
        console.error('Error fetching bookings from indexedDB:', error)
      }
    }

    fetchBookings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className="overflow-scroll w-full">
      <RouteTransition>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full">
            <div className="flex items-center justify-center w-full mt-8 mb-4">
              <figure className="[&>svg]:w-auto [&>svg]:h-[2rem] [&>svg]:relative [&>svg]:top-[2px] pr-[2px]">
                <UpSeatLogo />
              </figure>
            </div>
          </div>

          <div
            className={`flex flex-col gap-8 [&>div]:h-[100vh] ${screenSize.width > 1024 ? '[&>div]:w-[25vw]' : '[&>div]:w-[80vw]'} pt-4`}
          >
            {storedBookings.map((bookingOrder: Booking) =>
              bookingOrder.orders.map((order) => {
                return (
                  <TicketCardBackFace
                    key={getUniqueId()}
                    booking={bookingOrder}
                    order={order}
                  />
                )
              }),
            )}
          </div>
        </div>
      </RouteTransition>
    </main>
  )
}
