import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ChannelItem } from '@/types/preshow'
import { GridImage } from './GridImage'
import { GridVideo } from './GridVideo'
import { isVideo } from '@/utils'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'

interface GridProps {
  channelContent: ChannelItem[]
  childVariants: any
  channelContentLoaded: boolean
  badge_url: string
  video_url: string
  video_description: string
  name: string
  setChannelContentLoaded: (value: boolean) => void
}

export const GridTile = ({
  channelContent,
  badge_url,
  name,
  childVariants,
  channelContentLoaded,
  setChannelContentLoaded,
}: GridProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const { fullScreenChannel, setFullScreenIndex, setMainContent } =
    useFullScreenContentStore()
  const [videoResolution, setVideoResolution] = useState({
    width: 0,
    height: 0,
  })
  const [gridImagesLoaded, setGridImagesLoaded] = useState(false)
  const [gridVideosLoaded, setGridVideosLoaded] = useState(false)

  const imageRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const ref = useRef(null)

  useEffect(() => {
    if (gridVideosLoaded) {
      setChannelContentLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridImagesLoaded, gridVideosLoaded])

  useEffect(() => {
    const videoElement = videoRef.current
    const imageElement = imageRef.current

    const handleLoadedMetadata = () => {
      if (videoElement) {
        const width = videoElement.videoWidth
        const height = videoElement.videoHeight
        setVideoResolution({ width, height })
      }
    }

    if (videoElement)
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    if (imageElement)
      imageElement.addEventListener('load', handleLoadedMetadata)

    return () => {
      if (videoElement)
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      if (imageElement)
        imageElement.removeEventListener('load', handleLoadedMetadata)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      variants={childVariants}
      ref={ref}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: gridVideosLoaded ? 100 : 0 }}
      transition={{ staggerChildren: 0.1 }}
      className="flex flex-wrap h-full w-full overflow-hidden bg-purple"
    >
      {channelContent
        .filter((cont: ChannelItem) => isVideo(cont.content_url))
        .map((channelItem, i) => {
          console.log('channelItem', channelItem)
          // if (isVideo(channelItem.content_url)) {
          return (
            <motion.div
              key={channelContent[i].content_url}
              className="relative flex flex-col pt-1"
            >
              <motion.div>
                <GridVideo
                  url={channelItem.content_url}
                  description={channelItem.description as string}
                  videoResolution={videoResolution}
                  setCurrentEvent={() => {
                    setFullScreenIndex(
                      fullScreenChannel.findIndex(
                        (c) => channelItem.y_axis === c?.y_axis,
                      ),
                    )
                    setMainContent({
                      badge_url,
                      video_url: channelItem.content_url,
                      video_description: channelItem.description as string,
                      name,
                    })
                    navigate(`/fullscreen/${params.id ?? ''}`)
                  }}
                  setGridVideosLoaded={setGridVideosLoaded}
                  gridVideosLoaded={gridVideosLoaded}
                />
              </motion.div>
            </motion.div>
          )
          //   )
          // } else return null
          // else {
          //   return (
          //     <motion.div
          //       className={`w-[50%] pt-1 ${channelItem.x_axis === 0 && 'pr-[.125rem]'} ${channelItem.x_axis === 1 && 'pl-[.125rem]'}`}
          //       key={channelItem.content_url}
          //     >
          //       <GridImage
          //         url={channelItem.content_url}
          //         alt={channelItem.description ?? channelItem.content_url}
          //         setGridImagesLoaded={setGridImagesLoaded}
          //         gridImagesLoaded={gridImagesLoaded}
          //       />
          //     </motion.div>
          //   )
          // }
        })}
    </motion.div>
  )
}
