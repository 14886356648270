import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { ExpandableText } from '@/components/upseat-ui/contentChannel/ExpandableText'
import { HouseIcon } from '@/assets/HouseIcon'
import { PlayIcon } from '@/assets/PlayIcon'
import { SeekBar } from '@/components/upseat-ui/contentChannel/SeekBar'
import { Skeleton } from '@/components/ui/skeleton'
import { UpSeatLogo } from '@/assets/upseat_logo'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'
import { useUserStore } from '@/context/useUserStore'

export const FullScreen = () => {
  const {
    mainContent,
    fullScreenChannel,
    currentEvent,
    fullScreenIndex,
    setCurrentEvent,
    setFullScreenIndex,
  } = useFullScreenContentStore()
  const params = useParams()
  const navigate = useNavigate()
  const { user } = useUserStore()
  const screenSize = useScreenSize()
  const videoRef = useRef<HTMLVideoElement>(null)
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const [duration, setDuration] = useState(0)
  const [isExclusive, setIsExclusive] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [badgeLoaded, setBadgeLoaded] = useState(false)

  useEffect(() => {
    setCurrentEvent(fullScreenChannel[fullScreenIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFullScreenIndex])

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying && videoRef.current.paused) {
        setIsMuted(false)
        videoRef.current.play().catch(() => {
          setIsPlaying(false)
        })
      } else if (!isPlaying && !videoRef.current.paused) {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    if (swipeDirection) {
      handleSkipContent(swipeDirection === 'up')
      resetSwipeDirection()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  type Direction = 'next' | 'prev'
  const navigateContent = (direction: Direction) => {
    let newIndex
    if (direction === 'next') {
      newIndex = (fullScreenIndex + 1) % fullScreenChannel.length
    } else {
      newIndex =
        fullScreenIndex - 1 < 0
          ? fullScreenChannel.length - 1
          : fullScreenIndex - 1
    }

    setFullScreenIndex(newIndex)
    setCurrentEvent(fullScreenChannel[newIndex])

    // setting meet the cast content in the last position of the fullScreenChannel
    if (
      (newIndex === 1 && direction === 'next') ||
      (newIndex === 0 && direction === 'prev')
    ) {
      navigate(`/meet-the-cast/${params.id ?? ''}?feed=true`)
    }
  }

  const handlePlay = useCallback(() => {
    setIsMuted(true)
    if (videoRef.current) {
      // Attempt to play the video
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true)
        })
        .catch(() => {
          setIsPlaying(false)
        })
    }
  }, [])

  const handleSkipContent = (next?: boolean) => {
    next ? navigateContent('next') : navigateContent('prev')
    setCurrentTime(0)
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    setContentLoaded(true)
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        id="content-full-screen"
        className={`relative m-0 w-full h-full flex flex-col justify-center bg-[black] ${screenSize.width > 1024 && 'px-[33vw]'}`}
      >
        {!!user && (
          <motion.span
            onClick={() => {
              navigate(`/tickets`)
            }}
            className="fixed cursor-pointer top-0 right-0 m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
          >
            <HouseIcon />
          </motion.span>
        )}

        {isExclusive && (
          <div className="w-full h-auto z-50 flex justify-center">
            <span className="absolute top-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">{`Exclusive`}</span>
          </div>
        )}

        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => {
            setFullScreenIndex(0)
            navigate(`/channel/${params.id ?? ''}`)
          }}
          className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
        >
          <Arrow />
        </motion.span>

        <div className="relative w-full h-full">
          {contentLoaded && !isPlaying && (
            <div
              className={`absolute top-0 ${screenSize.width > 1024 ? 'w-[33vw]' : 'w-[100vw]'} h-full flex items-center justify-center`}
            >
              <div
                className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
                onClick={handlePlay}
              >
                <PlayIcon />
              </div>
            </div>
          )}

          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: contentLoaded ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={videoRef}
            controls={false}
            autoPlay={isPlaying}
            loop
            muted={isMuted}
            playsInline={true}
            className={`absolute top-0 w-full h-[100svh] object-contain ${contentLoaded ? '' : 'hidden'}`}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            onClick={() => setIsPlaying(!isPlaying)}
            src={currentEvent?.content_url}
          />

          <motion.div
            className="absolute w-[100vw] h-[100vh] pb-[12.5vh] flex justify-center items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: !contentLoaded ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, transition: { duration: 0 } }}
          >
            <Skeleton className="flex flex-col gap-2 justify-center items-center [&>svg]:w-auto [&>svg]:h-[2.5rem] [&>svg]:relative [&>svg]:top-[2px] pr-[2px]">
              <UpSeatLogo />
            </Skeleton>
          </motion.div>

          <div className="absolute inset-0 z-20 pointer-events-none" />

          <div className="w-full items-center justify-between absolute py-4 p-4 bottom-0 flex bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]">
            <div className="flex flex-col w-full">
              <div className="flex gap-2 items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <img
                    src={mainContent.badge_url}
                    alt="preshowOne"
                    draggable={false}
                    className={`w-8 h-8 rounded-[4px] ${badgeLoaded ? '' : 'hidden'}`}
                    onLoad={() => setBadgeLoaded(true)}
                    loading="lazy"
                  />
                  {!badgeLoaded && (
                    <Skeleton className="w-8 h-8 rounded-[4px] bg-purple" />
                  )}
                  <p className="font-bold">{mainContent.name}</p>
                </div>

                {isPlaying && (
                  <div className="relative">
                    <div
                      className="cursor-pointer top-0 z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                      onClick={() => setIsMuted(!isMuted)}
                    >
                      {isMuted ? <VolumeOff /> : <VolumeOn />}
                    </div>
                  </div>
                )}
              </div>

              {currentEvent?.description?.length && (
                <div className="pt-3">
                  <ExpandableText
                    text={currentEvent?.description as string}
                    limit={150}
                  />
                </div>
              )}

              {/* Timeline (Seek Bar) */}
              {contentLoaded && (
                <div className="relative flex flex-col items-center bg-transparent py-4">
                  <SeekBar
                    currentIndex={0}
                    videoRef={videoRef}
                    duration={duration}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    isActive
                  />

                  {/* Custom styles to hide the default thumb */}
                  <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
