import { AnimatePresence, motion } from 'framer-motion'
import { Booking, Order } from '@/types/booking'
import { useEffect, useRef, useState } from 'react'

import { CtnChannelBtn } from './CtnChannelBtn'
import { TicketButton } from './TicketButton'
import TicketImage from './TicketImage'
import { TicketInfo } from './TicketInfo'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { useTicketsStore } from '@/context/useTicketsStore'

type TicketCardProps = {
  booking: Booking
  order: Order
  isFlipped: boolean
  isActiveTicket: boolean
  getSeatMap?: (order_id: string) => void
  handleFlip?: () => void
}

export function TicketCard({
  booking,
  order,
  isFlipped,
  isActiveTicket,
  getSeatMap,
  handleFlip,
}: TicketCardProps) {
  const navigate = useNavigate()
  const { setBooking } = useExistingBookingStore()
  const [loaded, setLoaded] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { ticketVideoOnMap, setTicketVideoOn } = useTicketsStore()
  const isVideoPlaying = ticketVideoOnMap[order.uid]

  booking.orders.map((order: Order) => {
    return order.tickets.sort((a, b) => {
      const matchA = a.seat_name.match(/\d+/)
      const matchB = b.seat_name.match(/\d+/)
      const numA = matchA ? parseInt(matchA[0]) : 0
      const numB = matchB ? parseInt(matchB[0]) : 0
      return numA - numB
    })
  })

  useEffect(() => {
    if (!isFlipped && isActiveTicket && booking.preshow.event_uid) {
      const videoTimeout = setTimeout(
        () => setTicketVideoOn(order.uid, true),
        2000,
      )

      return () => {
        clearTimeout(videoTimeout)
        setTicketVideoOn(order.uid, false)
        if (videoRef.current) {
          videoRef.current.currentTime = 0
          // eslint-disable-next-line react-hooks/exhaustive-deps
          videoRef.current.pause()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlipped, isActiveTicket])

  useEffect(() => {
    if (!isVideoPlaying) {
      const resetTimeout = setTimeout(() => {
        setTicketVideoOn(order.uid, false)
      }, 10000)

      return () => clearTimeout(resetTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoPlaying])

  useEffect(() => {
    if (isActiveTicket && isVideoPlaying && videoRef.current) {
      videoRef.current.play()
    } else if (!isActiveTicket && videoRef.current) {
      videoRef.current.pause()
      // Avoid resetting currentTime unnecessarily
      if (!isVideoPlaying) {
        videoRef.current.currentTime = 0
      }
    }
  }, [isActiveTicket, isVideoPlaying])

  return (
    <div
      id="ticket-card"
      className={`${
        order.state.upgrade.code === 3
          ? 'shadow-[0_0_10px_0px_yellow]'
          : 'shadow-[0_0_8px_0px_#CDC3FB]'
      } rounded-[35px] bg-white flex flex-col items-center w-[80vw] h-full max-h-[700px] max-w-[85vw]`}
    >
      <>{console.log(booking.preshow)}</>
      <div
        className={`overflow-hidden relative flex items-start justify-center w-full h-full rounded-[35px] bg-purple`}
      >
        {booking.preshow.event_uid && (
          <motion.div
            className={`absolute h-[inherit] w-full flex justify-center bg-[black]`}
            initial={{ opacity: 0 }}
            animate={{
              opacity: isVideoPlaying ? 1 : 0,
              zIndex: isVideoPlaying ? 1 : -1,
            }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, zIndex: -1 }}
            onClick={handleFlip}
          >
            <video
              key={booking.preshow.event_uid}
              ref={videoRef}
              className="shadow-md w-full h-full object-cover"
              controls={false}
              muted
              playsInline
              onEnded={() => setTicketVideoOn(order.uid, false)}
              src={booking.preshow.first_content.content_url}
            />

            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: isActiveTicket && isVideoPlaying ? 1 : 0,
                }}
                transition={{ delay: 1, duration: 0.5 }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                className="absolute w-full h-full flex justify-center items-end bottom-0 z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.5)_7.5%,rgba(0,0,0,0)_20%)]"
              >
                <CtnChannelBtn
                  id="ticket-more-content-btn"
                  className="my-4"
                  onClick={async () => {
                    await setBooking(booking)
                    navigate(`/channel/${booking.preshow.event_uid}`)
                  }}
                />
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
        <motion.div
          className={`absolute w-full h-[inherit]`}
          initial={{ opacity: 1 }}
          animate={{
            opacity: isVideoPlaying ? 0 : 1,
          }}
          transition={{ delay: isActiveTicket ? 1 : 0, duration: 0.5 }}
        >
          <TicketImage
            src={booking.thumbnail_url}
            loaded={loaded}
            setLoaded={setLoaded}
            handleFlip={handleFlip}
          />
        </motion.div>
      </div>
      <div
        className="w-full flex flex-col gap-2 items-center my-4"
        onClick={handleFlip && handleFlip}
      >
        <TicketInfo ticketInfo={booking} upgraded embeddedInTicket />
        {navigator.onLine ? (
          <div className="w-full justify-center relative flex gap-2 my-2">
            <TicketButton
              ticketStateCode={order.state.upgrade.code as number}
              ticketStateMsg={order.state.upgrade.msg}
              onClick={() => {
                if (order.state.upgrade.code === 1) {
                  getSeatMap && getSeatMap(order.config.uid)
                }
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
