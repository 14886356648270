import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SeekBar } from './SeekBar'
import { TicketsIconYellow } from '@/assets/TicketsIconYellow'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'

interface MainTileProps {
  childVariants: any
  badge_url: string
  video_url: string
  video_description: string
  name: string
  isExclusive: boolean
  setMainContentLoaded: (value: boolean) => void
}

export const MainTile = ({
  childVariants,
  badge_url,
  video_url,
  video_description,
  name,
  isExclusive,
  setMainContentLoaded,
}: MainTileProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const { setMainContent } = useFullScreenContentStore()

  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  // Update play/pause state based on visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsPlaying(entry.isIntersecting),
      { threshold: 0.6 },
    )
    if (containerRef.current) observer.observe(containerRef.current)

    return () => observer.disconnect()
  }, [])

  // Handle video play/pause
  useEffect(() => {
    const video = videoRef.current
    if (!video) return
    isPlaying ? video.play().catch(console.error) : video.pause()
  }, [isPlaying])

  // Handle metadata load and video readiness
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
      setMainContentLoaded(true)
    }
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) setCurrentTime(videoRef.current.currentTime)
  }

  return (
    <motion.div
      ref={containerRef}
      id="channel-main-video"
      variants={childVariants}
      className="relative m-0 w-full"
    >
      {!duration && (
        <div className="h-[66vh] bg-purple1 flex justify-center items-center [&>svg]:relative [&>svg]:bottom-4 [&>svg]:animate-pulse">
          <TicketsIconYellow />
        </div>
      )}

      <video
        ref={videoRef}
        autoPlay
        loop
        muted={isMuted}
        playsInline
        className={`w-full h-auto object-cover ${!duration && 'hidden'}`}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onClick={() => {
          setMainContent({ badge_url, video_url, video_description, name })
          navigate(`/fullscreen/${params.id ?? ''}`)
        }}
      >
        <source src={video_url} />
      </video>

      {isExclusive && (
        <span className="absolute top-0 right-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">
          Exclusive
        </span>
      )}

      <div className="absolute bottom-0 w-full flex flex-col gap-2 p-4 bg-gradient-to-t from-black/60 to-transparent">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img
              src={badge_url}
              alt={name}
              className="w-8 h-8 rounded"
              draggable={false}
            />
            <p className="font-bold">{name}</p>
          </div>
          <button
            className="bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <VolumeOff /> : <VolumeOn />}
          </button>
        </div>

        {duration > 0 && (
          <div className="relative flex flex-col items-center bg-transparent py-2">
            <SeekBar
              videoRef={videoRef}
              currentIndex={0}
              duration={duration}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              ref={videoRef}
              isActive
            />
            {/* Custom styles to hide the default thumb */}
            <style>
              {`
                        input[type='range']::-webkit-slider-thumb {
                          -webkit-appearance: none;
                          appearance: none;
                          width: 0;
                          height: 0;
                        }
                        input[type='range']::-moz-range-thumb {
                          width: 0;
                          height: 0;
                        `}
            </style>
          </div>
        )}
      </div>
    </motion.div>
  )
}
