import { PriceBand, Seat } from '@/types'

import { AnimatePresence } from 'framer-motion'
import { BookingDetails } from '@/components/upseat-ui/seatMap/BookingDetails/index'
import { CartSlide } from '@/components/upseat-ui/wallet/CartSlide'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { SeatMap } from '@/components/upseat-ui/seatMap'
import { createBasket } from '@/service/Basket/postBasket'
import { deleteBasket } from '@/service/Basket/deleteBasket'
import { getPlan } from '@/service/Seats/getPlan'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useEffect } from 'react'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

// Function to get consecutive seat groups
export const getConsecutiveSeatGroups = (seats: string | any[]) => {
  const consecutiveGroups = []
  let currentGroup = [seats[0]]

  for (let i = 1; i < seats.length; i++) {
    if (seats[i].number - seats[i - 1].number === 1) {
      // Seats are consecutive, add to current group
      currentGroup.push(seats[i])
    } else {
      // Seats are not consecutive, start a new group
      consecutiveGroups.push([...currentGroup])
      currentGroup = [seats[i]]
    }
  }

  for (let i = 1; i < seats.length; i++) {
    if (seats[i].location - seats[i - 1].location <= 1) {
      // Seats are consecutive or neighbors, add to current group
      currentGroup.push(seats[i].location)
    } else {
      // Seats are not consecutive or neighbors, start a new group
      consecutiveGroups.push([...currentGroup.sort((a, b) => a - b)])
      currentGroup = [seats[i].location]
    }
  }

  // Add the last group
  consecutiveGroups.push([...currentGroup])

  return consecutiveGroups.filter((group) => group.length >= 2)
}

const Upbooking = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const navigate = useNavigate()
  const { order, booking } = useExistingBookingStore()
  const { user } = useUserStore()
  const { basket, setBasket } = useTicketsStore()
  const {
    mapSeats,
    setMapSeats,
    setPriceBands,
    setSelectedSeats,
    selectedSeats,
  } = useEventStore()
  const { setServerError } = useAppStore()

  const goBack = async () => {
    await setMapSeats(undefined)
    await setPriceBands([])
    callRouteWithDirection('/tickets', true, 1)

    if (basket) {
      await deleteBasket(basket?.upgrade_uid)
    }
  }

  useEffect(() => {
    ;(!booking || !mapSeats) && callRouteWithDirection('/tickets', true, 1)

    booking &&
      order &&
      !basket &&
      createBasket(order.uid)
        .then((res) => {
          setBasket({
            uid: res.basket.uid,
            upgrade_uid: res.upgrade.uid,
            instance_uid: res.basket.instance_uid,
            // total_discount: res.basket.total_discount,
            total_price: res.basket.total_price,
            payment_confirmed: res.basket.payment_confirmed,
            payment_attached: res.basket.payment_attached,
            seats: res.basket.seats,
            expires_utc: res.basket.expires_utc,
          })

          return res.data
        })
        .catch((err) => {
          console.log('postNewBasket Error:', err)
          setServerError(true)
          goBack()
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSeatsAvailability = async () => {
    const plan = await getPlan(order?.uid as string)

    if (!plan.bands) return

    const priceBands = plan.bands
      .sort(
        (a: PriceBand, b: PriceBand) =>
          (a.discount_price ?? 0) - (b.discount_price ?? 0),
      )
      .filter(
        (band: PriceBand) => band.discount_price && band.discount_price > 0,
      )

    setPriceBands(priceBands)

    const updatedSeats = plan.seats.map((seat: Seat) => {
      const priceBandIndex = priceBands.findIndex(
        (priceBand: PriceBand) => priceBand.uid === seat.band_uid,
      )
      return {
        ...seat,
        tier: priceBandIndex + 1,
        upgrade_price: priceBands[priceBandIndex]?.discount_price,
        original_price: priceBands[priceBandIndex]?.original_price,
        discount: priceBands[priceBandIndex]?.active_discount,
      }
    })

    !updatedSeats.length && navigate('/tickets')
    setMapSeats(updatedSeats)
  }

  useEffect(() => {
    if (user && !selectedSeats.length) {
      const interval = setInterval(async () => {
        await updateSeatsAvailability()
        setSelectedSeats([])
      }, 7500)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedSeats])

  return (
    <>
      <DefaultLayout>
        <RouteTransition>
          <div className="flex flex-col justify-start">
            <HeadlineBar
              title={
                <div className="flex items-center">{`choose your UpSeats`}</div>
              }
              goBack={goBack}
            />
            <div className="select-seats w-full">
              <div
                className={`bg-white text-purple h-full pb-[20vh] flex flex-col`}
              >
                {booking && (
                  <>
                    <BookingDetails bookingData={booking} />
                    <SeatMap bookingData={booking} />
                  </>
                )}
              </div>
            </div>
          </div>
        </RouteTransition>
      </DefaultLayout>

      {selectedSeats.length && (
        <AnimatePresence>
          <CartSlide />
        </AnimatePresence>
      )}
    </>
  )
}

export default Upbooking
