export const MerchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.75 12V8C8.75 6.93913 9.17143 5.92172 9.92157 5.17157C10.6717 4.42143 11.6891 4 12.75 4C13.8109 4 14.8283 4.42143 15.5784 5.17157C16.3286 5.92172 16.75 6.93913 16.75 8V12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.44265 12.668C4.58765 10.927 4.66065 10.057 5.23465 9.528C5.80865 8.999 6.68265 9 8.42965 9H17.0687C18.8147 9 19.6887 9 20.2627 9.528C20.8367 10.056 20.9097 10.927 21.0547 12.668L21.5687 18.834C21.6527 19.847 21.6947 20.354 21.3987 20.677C21.1007 21 20.5927 21 19.5747 21H5.92265C4.90565 21 4.39665 21 4.09965 20.677C3.80265 20.354 3.84465 19.847 3.92965 18.834L4.44265 12.668Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}
