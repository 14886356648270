import { Plus, RotateCw } from 'lucide-react'

import { AllScreenBlur } from '@/components/upseat-ui/slides/AllScreenBlur'
import { AnimatePresence } from 'framer-motion'
import { BellIcon } from '@/assets/BellIcon'
import { Booking } from '@/types/booking'
import { Button } from '@/components/ui/button'
import { EmailIcon } from '@/assets/EmailIcon'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { InfoIcon } from '../../assets/infoIcon'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import { Modal } from '@/components/upseat-ui/modals'
import NavLayout from '@/layouts/NavLayout'
import { Notification } from '@/components/upseat-ui/notifications/Notification'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { User } from '@/types'
import { findBooking } from '@/service/Booking/findBooking'
import { syncBooking } from '@/service/Booking/syncBooking'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTicketsStore } from '@/context/useTicketsStore'

type SyncProps = {
  user?: User
}

export const Sync = ({ user }: SyncProps) => {
  const navigate = useNavigate()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { setWallet } = useTicketsStore()
  const [syncLoading, setSyncLoading] = useState<boolean>(false)
  const [phoneNumberNotification, setPhoneNumberNotification] =
    useState<boolean>(false)
  const [emailConfirmationNotification, setEmailConfirmationNotification] =
    useState<boolean>(false)
  const [noTicketsToSync, setNoTicketsToSync] = useState<boolean>(false)

  // useEffect(() => {
  //   if (user && (!user?.phoneNumber || !textAlert)) {
  //     setTimeout(() => {
  //       setPhoneNumberNotification(true)
  //     }, 3000)
  //   }

  //   if (user && (!user?.email || !emailAlert)) {
  //     setTimeout(() => {
  //       setEmailConfirmationNotification(true)
  //     }, 3000)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const [loading, setLoading] = useState<boolean>(false)
  const [infoModal, setInfoModal] = useState<boolean>(false)
  const [bookingSearch, setBookingSearch] = useState<string>('')
  const [searchError, setSearchError] = useState<string>('')
  const { wallet } = useTicketsStore()

  const searchBookingRef = async () => {
    setLoading(true)

    if (user) {
      findBooking(bookingSearch)
        .then((response: any) => {
          if (
            wallet?.find(
              (booking: Booking) =>
                booking.event_name + booking.start_time ===
                response.event_name + response.start_time,
            )
          ) {
            callRouteWithDirection('/tickets', false, 1)
          }
        })
        .catch((error) => {
          setSearchError('Booking not found')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  return (
    <NavLayout>
      <div className="flex justify-between items-center">
        {noTicketsToSync && (
          <AllScreenBlur dimApp close={() => !noTicketsToSync}>
            <div className="w-full bg-red z-[999] p-4 rounded-[10px]">
              <h1>{`No tickets to sync`}</h1>
            </div>
          </AllScreenBlur>
        )}

        <HeadlineBar
          title={'sync a ticket'}
          goBack={() => callRouteWithDirection('/tickets', true, 1)}
        />
        <div className="mr-5 pb-1">
          <div className={`${syncLoading && 'animate-spin'}`}>
            <RotateCw
              color="white"
              size={20}
              className="cursor-pointer"
              onClick={() => {
                setSyncLoading(true)
                setWallet([])
                syncBooking()
                  .then(async () => {
                    callRouteWithDirection('/tickets', true, 1)
                  })
                  .catch(async () => {
                    setNoTicketsToSync(true)

                    setTimeout(() => {
                      setNoTicketsToSync(false)
                    }, 2000)
                  })
                  .finally(() => {
                    setSyncLoading(false)
                  })
              }}
            />
          </div>
        </div>
      </div>

      <div className="sync relative px-6" data-testid="sync">
        <RouteTransition>
          <section className="flex flex-col gap-24 my-3">
            <div className="flex flex-col gap-4">
              <p>
                {`Enter your`}
                <span
                  data-testid="info-box-link"
                  className="relative text-purple5 mx-1"
                  onClick={() => setInfoModal(true)}
                >
                  {`booking reference number`}
                </span>
                {`and we’ll find your tickets for you.`}
              </p>
              {!syncLoading ? (
                <>
                  <Input
                    data-testid="search-input"
                    className={`bg-transparent border ${searchError ? 'border-red' : 'border-purple5'} text-purple5 placeholder:text-purple5`}
                    placeholder={`Enter your booking reference number`}
                    value={bookingSearch}
                    readOnly={!user}
                    maxLength={10}
                    onChange={(e) => {
                      user &&
                        setBookingSearch(e.target.value.replace(/\./g, ''))
                      setSearchError('')
                    }}
                  />
                  {searchError && (
                    <p className="text-red pl-2 text-[12px] text-balance relative top-[-5px]">
                      {searchError}
                    </p>
                  )}
                  <Modal
                    modalOpen={infoModal}
                    content={
                      <div className="relative bg-purple3 rounded-[20px] p-4 pr-[10%] flex gap-2">
                        <span className="pt-[2px]">
                          <InfoIcon />
                        </span>
                        <p className="w-fit text-white text-sm leading-snug text-pretty">
                          {`Your booking reference is found in the email you received when you ordered your original ticket. It is usually a string of random letters and numbers.`}
                        </p>
                        <Plus
                          color="white"
                          data-testid="info-box-closeIcon"
                          onClick={() => setInfoModal(false)}
                          style={{
                            rotate: '45deg',
                            position: 'absolute',
                            right: '12px',
                            top: '7px',
                          }}
                          width={16}
                          height={16}
                        />
                      </div>
                    }
                  />

                  {!loading ? (
                    <Button
                      variant="primaryYellow"
                      className={`w-full ${!bookingSearch && 'bg-purple2 opacity-50'}`}
                      disabled={!bookingSearch}
                      onClick={() => searchBookingRef()}
                    >
                      {`Find my tickets`}
                    </Button>
                  ) : (
                    <div className="pt-12 pb-8">
                      <LoadingIndicator />
                    </div>
                  )}
                </>
              ) : (
                <div className="my-4">
                  <LoadingIndicator />
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <p>{`Found a bug with the app?`}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
              >
                <Button
                  variant="secondaryDark"
                  className="py-2 px-4 text-sm font-normal text-[yellow] border-[yellow]"
                >
                  {`Let us know`}
                </Button>
              </a>
            </div>
          </section>
        </RouteTransition>
      </div>

      {useConditionalRender(
        phoneNumberNotification,
        <AnimatePresence>
          <Notification
            icon={<BellIcon />}
            title={`Stay updated on your UpSeats`}
            size="small"
            content={
              <>
                <span
                  className="text-yellow"
                  onClick={() => {
                    if (!user?.phoneNumber) {
                      navigate('/personal-details')
                    } else {
                      navigate('/account')
                    }
                  }}
                >{`Add your phone number `}</span>
                {`to receive alerts`}
              </>
            }
            onClose={() => setPhoneNumberNotification(false)}
          />
        </AnimatePresence>,
      )}

      {useConditionalRender(
        emailConfirmationNotification,
        <AnimatePresence>
          <Notification
            icon={<EmailIcon />}
            title={`Verify your email`}
            size="large"
            content={
              <>
                <p
                  style={{
                    lineHeight: '1rem',
                  }}
                  className="pb-1"
                >{`We sent you an email to confirm your account.`}</p>
                <span
                  className="text-yellow"
                  onClick={() => setEmailConfirmationNotification(false)}
                >{`Resend Email`}</span>
              </>
            }
            onClose={() => setEmailConfirmationNotification(false)}
          />
        </AnimatePresence>,
      )}
    </NavLayout>
  )
}
