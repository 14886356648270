import './index.scss'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { useAppStore } from './context/useAppStore'
import { useContentChannelStore } from './context/useContentChannelStore'
import { useMeetTheCastStore } from './context/useMeetTheCastStore'
import { useTicketsStore } from './context/useTicketsStore'
import { useUserStore } from './context/useUserStore'

if ((window as any).Cypress) {
  ;(window as any).stores = {
    appStore: useAppStore,
    userStore: useUserStore,
    walletStore: useTicketsStore,
    contentChannelStore: useContentChannelStore,
    meetTheCastStore: useMeetTheCastStore,
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
)

// PWA configuration
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
