export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M5.875 10.5947C5.77554 10.5947 5.68016 10.6342 5.60984 10.7045C5.53951 10.7748 5.5 10.8702 5.5 10.9697V20.7197C5.5 20.9267 5.668 21.0947 5.875 21.0947H18.625C18.7245 21.0947 18.8198 21.0552 18.8902 20.9848C18.9605 20.9145 19 20.8191 19 20.7197V10.9697C19 10.8702 18.9605 10.7748 18.8902 10.7045C18.8198 10.6342 18.7245 10.5947 18.625 10.5947H17.125C16.8266 10.5947 16.5405 10.4761 16.3295 10.2652C16.1185 10.0542 16 9.76804 16 9.46967C16 9.1713 16.1185 8.88515 16.3295 8.67417C16.5405 8.4632 16.8266 8.34467 17.125 8.34467H18.625C20.074 8.34467 21.25 9.52067 21.25 10.9697V20.7197C21.25 21.4159 20.9734 22.0835 20.4812 22.5758C19.9889 23.0681 19.3212 23.3447 18.625 23.3447H5.875C5.17881 23.3447 4.51113 23.0681 4.01884 22.5758C3.52656 22.0835 3.25 21.4159 3.25 20.7197V10.9697C3.25 9.52067 4.426 8.34467 5.875 8.34467H7.375C7.67337 8.34467 7.95952 8.4632 8.1705 8.67417C8.38147 8.88515 8.5 9.1713 8.5 9.46967C8.5 9.76804 8.38147 10.0542 8.1705 10.2652C7.95952 10.4761 7.67337 10.5947 7.375 10.5947H5.875ZM11.9845 1.11017C12.0193 1.07525 12.0607 1.04754 12.1063 1.02864C12.1518 1.00973 12.2007 1 12.25 1C12.2993 1 12.3482 1.00973 12.3937 1.02864C12.4393 1.04754 12.4807 1.07525 12.5155 1.11017L16.8595 5.45417C16.9121 5.50662 16.9479 5.5735 16.9624 5.64633C16.977 5.71916 16.9695 5.79466 16.9411 5.86327C16.9126 5.93187 16.8645 5.99049 16.8027 6.03169C16.7409 6.07288 16.6683 6.0948 16.594 6.09467H13.375V14.7197C13.375 15.018 13.2565 15.3042 13.0455 15.5152C12.8345 15.7261 12.5484 15.8447 12.25 15.8447C11.9516 15.8447 11.6655 15.7261 11.4545 15.5152C11.2435 15.3042 11.125 15.018 11.125 14.7197V6.09467H7.906C7.83173 6.0948 7.7591 6.07288 7.69731 6.03169C7.63552 5.99049 7.58735 5.93187 7.55891 5.86327C7.53047 5.79466 7.52305 5.71916 7.53757 5.64633C7.5521 5.5735 7.58792 5.50662 7.6405 5.45417L11.9845 1.11017Z"
        fill="white"
      />
    </svg>
  )
}
