import { formattedDate, seatNamesFormatted, upSeatLogoLink } from '@/utils'

import { Booking } from '@/types/booking'
import { Plus } from 'lucide-react'
import useExistingBookingStore from '@/context/useExistingBookingStore'

type BookingDetailsProps = {
  bookingData: Booking
}

export const BookingDetails = ({ bookingData }: BookingDetailsProps) => {
  const { order } = useExistingBookingStore()
  // Split the formatted date into date and time parts
  const [date, time] = formattedDate(bookingData.start_time as Date)
    .replace(/,([^,]*, [^,]*),([^,]*)$/, '$1,$2')
    .split(',')

  return (
    <div
      className={`show-card bg-purple6 relative flex flex-col gap-4 w-full p-3 px-6`}
    >
      <div className="info-container flex gap-4 items-center">
        <div className={`show-image rounded-md aspect-square bg-grey h-[72px]`}>
          <figure
            className={`h-full bg-cover bg-center rounded-[10px]`}
            style={{
              backgroundImage: `url(${bookingData.thumbnail_url.length ? bookingData.thumbnail_url : upSeatLogoLink})`,
            }}
          />
        </div>

        <div className="overflow-hidden info basis-[75%] flex flex-col gap-1 justify-between text-purple">
          <p className="font-[400] text-purple2">{`Your current seats`}</p>
          <div className="flex items-center gap-1 leading-[normal] pb-1">
            <span className="border!bg-[white] w-[13px] border-[2px] border-[black] top-[-.5px] relative w-[16px] h-[16px] p-0 rounded-[5px]">
              <Plus
                color="black"
                className="absolute rotate-45 top-[-2px] right-[-2px]"
                width={16}
                height={16}
              />
            </span>
            <p className="font-bold overflow-hidden capitalize text-ellipsis text-nowrap">
              {order && seatNamesFormatted(order.tickets)}
            </p>
          </div>

          <div className="flex gap-1 pt-1 items-center border-t-[1px] border-purple9 w-[90%]">
            {bookingData.theatre_name && (
              <div className="flex py-1 text-nowrap max-w-[50%] items-baseline gap-1">
                <p className="leading-none capitalize text-purple2 text-[12px] overflow-hidden text-ellipsis text-nowrap">
                  {bookingData.event_name.toLowerCase()}
                </p>
              </div>
            )}
            <p className="date text-purple2 text-[12px] text-nowrap">
              {' | '}
              {date}
              <span>, {time}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
