import ArtsBanner from '@/assets/ArtsBanner.png'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import KilnBanner from '@/assets/KilnBanner.png'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'

export const Partners = () => {
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={<div className="flex items-center">{`partners`}</div>}
            goBack={() => callRouteWithDirection('/tickets', true, 3)}
          />
        </div>
        <main
          className="bg-white overflow-scroll flex flex-col gap-2 text-purple h-[90svh] p-4 py-6"
          data-testid="partners"
        >
          <p className="font-bold">{`Partnered with`}</p>
          <div className="flex flex-col gap-4" data-testid="partners-list">
            <img
              src={KilnBanner}
              alt="UpSeat Logo"
              className="w-full"
              draggable={false}
            />
            <img
              src={ArtsBanner}
              alt="UpSeat Logo"
              className="w-full"
              draggable={false}
            />
          </div>
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
