import { apiClient } from '../axios'

export const postLogin = async (username: string, password: string) => {
  try {
    const response = (await Promise.race([
      apiClient.post('auth/token', {
        username,
        password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Request timed out'))
        }, 20000) // 20 second timeout
      }),
    ])) as any

    return response?.data
  } catch (error) {
    console.log('Error in postLogin:', error)
  }
}
